<template>
  <div style="padding: 20px; height: 100%">
    <el-form :inline="true" class="demo-form-inline">
      <div class="df aic">
        <el-form-item size="medium" class="mr10" style="flex: 0 0 16%">
          <el-input
            placeholder="关键词"
            v-model="query.keyword"
            clearable
            class="input-with-select"
            @clear="onSubmit"
          >
          </el-input>
        </el-form-item>
        <el-button
          type="success"
          size="medium"
          @click="onSubmit"
          class="mr10"
          style="height: 36px; border: none !important; margin-bottom: 20px"
          >查询</el-button
        >
      </div>
    </el-form>
    <el-table :data="list" style="width: 100%">
      <el-table-column
        prop="chipName"
        label="芯片名称"
        width="300"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="createdTime"
        label="创建时间"
        width="300"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="address" label="统计" width="300" align="center">
        <!-- <template slot-scope="scope"> -->
        <template slot-scope="scope">
          <el-button
            type="text"
            class="disib"
            size="mini"
            @click="openDialog(scope.row.id)"
            >次数统计</el-button
          >
          <el-button
            type="text"
            class="disib"
            size="mini"
            @click="openModeDialog(scope.row.id)"
            >模式统计</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="df" style="justify-content: flex-end; padding: 20px 0">
      <el-pagination
        background
        :page-size="query.limit"
        layout="total,prev, pager, next"
        :total="total"
        :current-page="query.page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
    <div v-if="dialogTitle">
      <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="50%"
        @closed="editId = null"
      >
        <div style="height: 800px" class="scroll-box">
          <div class="df aic">
            <el-form :inline="true" class="demo-form-inline">
              <el-form-item size="medium" class="mr10">
                <el-select
                  v-model="selectValue"
                  placeholder="请选择"
                  @change="selectChange"
                >
                  <el-option
                    v-for="item in modeList"
                    :key="item.id"
                    :label="item.modeName"
                    :value="item.modeId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div style="background: #fff">
            <!-- <Echart
            :options="options2"
            height="480px"
            width="100%"
            @getParams="getParams"
          ></Echart> -->
            <div
              v-loading="chartsLoading2"
              id="echarts2"
              :style="{ height: '480px', width: '100%' }"
            />
          </div>
        </div>
      </el-dialog>
    </div>

    <div v-if="dialogTitle1">
      <el-dialog
        :title="dialogTitle1"
        :visible.sync="dialogVisible1"
        width="50%"
        @closed="editId = null"
      >
        <div style="height: 800px" class="scroll-box">
          <el-form :inline="true" class="demo-form-inline">
            <div class="df">
              <el-form-item size="medium" class="mr10">
                <el-date-picker
                  v-model="pickerTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="timeChange"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item size="medium" class="mr10">
                <el-button
                  type="success"
                  size="medium"
                  @click="onSubmit1"
                  class="mr10"
                  style="
                    height: 36px;
                    border: none !important;
                    margin-bottom: 20px;
                  "
                  >查询</el-button
                >
              </el-form-item>
            </div>
          </el-form>
          <div style="height: 480px; width: 100%" v-loading="chartsLoading1">
            <div
              id="echarts1"
              :style="{ height: '480px', width: '100%' }"
              v-if="!isEmpty"
            />
            <el-empty description="暂无记录" v-else></el-empty>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from "@/util/extra-api";
import dayjs from "dayjs";
// import Echart from "@/common/echart";
import bus from "@/util/bus";

export default {
  data() {
    return {
      query: {
        page: 1,
        limit: 10,
        keyword: "",
        companyId: null,
      },
      dialogTitle1: "统计",
      dialogTitle: "统计",
      tableData: [],
      total: 0,
      dialogVisible: false,
      dialogVisible1: false,
      list: [],
      modeList: [],
      modeTotal: 0,
      form: {
        name: "",
        steps: [[]],
      },
      checkedId: null,
      modeQuery: {
        startTime: "",
        endTime: "",
      },
      pickerTime: "",
      options1: null,
      options2: null,
      chartsLoading1: true,
      chartsLoading2: true,
      selectValue: "",
      chart1: null,
      chart2: null,
      isEmpty: false,
    };
  },
  mounted() {
    this.query.companyId = localStorage.getItem("company_id");
    this.getList();
  },
  // components: {
  //   Echart,
  // },
  computed: {},
  methods: {
    getParams(params) {
      //console.log(params)
      bus.$emit("clickBar", params);
    },
    selectChange(value) {
      this.getCharts2(value);
    },
    timeChange(value) {
      if (value) {
        this.modeQuery.startTime = dayjs(value[0]).format("YYYY-MM-DD");
        this.modeQuery.endTime = dayjs(value[1]).format("YYYY-MM-DD");
      } else {
        this.modeQuery.startTime = "";
        this.modeQuery.endTime = "";
      }
    },
    openModeDialog(id) {
      this.checkedId = id;
      this.getModeList();
      this.dialogVisible = true;
    },
    getList() {
      api.get(`/v1/pc/deviceMode/chipList`, this.query).then((res) => {
        this.list = res.data;
        this.total = res.total;
      });
    },
    openDialog(id) {
      this.checkedId = id;
      this.getCharts1();
      this.dialogVisible1 = true;
    },
    getModeList() {
      api
        .get(`/v1/pc/deviceMode/chipModeList`, {
          chipId: this.checkedId,
        })
        .then((res) => {
          this.modeList = res.data;
          this.modeTotal = res.total;
          this.selectValue = res.data[0].modeId;
          if (res.data.length) {
            this.getCharts2(res.data[0].modeId);
          }
        });
    },
    getCharts1() {
      this.chartsLoading1 = true;
      this.isEmpty = false;
      // this.chartTestData1.push({
      //   name:'测试1',
      //   value:100
      // })
      api
        .get(`/v1/pc/deviceMode/chart1`, {
          ...this.modeQuery,
          chipId: this.checkedId,
          companyId: this.query.companyId,
        })
        .then((res) => {
          if (res.data.length) {
            this.options1 = {
              tooltip: {
                trigger: "item",
              },
              legend: {
                top: "5%",
                x: "left",
                textStyle: {
                  color: "#000",
                },
                orient: "vertical",
              },
              // nameTextStyle
              grid: {
                left: "30%",
              },
              series: [
                {
                  name: "模式使用次数",
                  type: "pie",
                  center: ["60%", "50%"],
                  radius: ["30%", "90%"],
                  avoidLabelOverlap: false,
                  itemStyle: {
                    borderRadius: 10,
                    borderColor: "#fff",
                    borderWidth: 2,
                  },
                  label: {
                    show: false,
                    position: "center",
                  },
                  labelLine: {
                    show: false,
                  },
                  data: res.data.map((v) => {
                    return {
                      value: v.y,
                      name: v.x,
                    };
                  }),
                  // data:this.chartTestData1
                },
              ],
            };

            let dom = document.querySelector("#echarts1");
            this.chart1 = this.$echarts.init(dom, "tdTheme");
            this.chart1.setOption(this.options1, true);
          } else {
            this.isEmpty = true;
          }
          this.chartsLoading1 = false;
        });
    },
    getCharts2(id) {
      this.chartsLoading2 = true;
      api
        .get(`/v1/pc/deviceMode/chart2`, {
          chipId: this.checkedId,
          modeId: id,
          companyId: this.query.companyId,
        })
        .then((res) => {
          this.options2 = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: [
              {
                type: "category",
                data: res.data.map((v) => v.x),
                // data:this.chartTestData2.map(v=>v.x),
                axisTick: {
                  alignWithLabel: true,
                },
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: [
              {
                barWidth: "24",
                name: "Direct",
                type: "bar",
                data: res.data.map((v) => v.y.length),
                // data:this.chartTestData2.map(v=>v.y),
              },
            ],
          };
          let dom = document.querySelector("#echarts2");

          this.chart2 = this.$echarts.init(dom, "tdTheme");
          this.chart2.setOption(this.options2, true);
          this.chartsLoading2 = false;
          // console.log(this.chartsLoading2,123123);
        });
    },
    onSubmit1() {
      this.getCharts1();
    },
    onSubmit() {
      this.query.page = 1;
      this.getList();
    },
    pageChange(page) {
      this.query.page = page;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.jcb {
  justify-content: space-between;
}
/deep/ .el-card__header {
  padding: 10px;
}
/deep/ .el-slider__marks-text {
  width: 30px !important;
}
/deep/ .el-table__empty-block {
  width: 100% !important;
}

.scroll-box::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 0;
}

.scroll-box::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

.scroll-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  transition: color 0.2s ease;
  cursor: pointer;
}
</style>